import React, { FC } from 'react';
import { Link, graphql } from 'gatsby';
import classNames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import TagLinksList from 'components/common/TagLinksList';

import { IArticlePreviewComponentProps } from './model';

import './ArticlePreview.scss';

const ArticlePreview: FC<IArticlePreviewComponentProps> = ({
  link,
  title,
  tags,
  previewImage,
  isVideo = false,
  className,
}) => {
  return (
    <Link to={link} className={classNames('article-preview', className)}>
      <div
        className={classNames('article-preview__img', { 'article-preview__img--video': isVideo })}
      >
        {previewImage?.image?.gatsbyImage?.childImageSharp ? (
          <UniversalImage img={previewImage.image} imageAlt={previewImage?.imageAlt || ''} />
        ) : null}
        {isVideo ? <span className="article-preview__play-button" /> : null}
      </div>

      <article className="article-preview__card">
        <p className="article-preview__title">{title}</p>
        {tags?.length ? (
          <TagLinksList
            tagsFormat="tinyLabel"
            tagsList={tags.slice(0, 2).map(({ title: name }) => ({ name }))}
          />
        ) : null}
      </article>
    </Link>
  );
};

/**
 * ATTENTION:
 * fragment FragmentArticlePreviewCardFromArticlePage should mirror query
 * from utils/algolia/kidsHubArticlesList.js
 *
 */

export const query = graphql`
  fragment FragmentArticlePreviewCard on TFeaturedItemsArticles {
    link: url
    title
    tags {
      id
      title
      taxonomy
    }
    isVideo
    previewImage {
      imageAlt
      image {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fixed(width: 150, height: 150, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }

  fragment FragmentArticlePreviewCardFromArticlePage on IAdvancedChildrenHubArticlePage {
    id
    isVideoPage
    link
    title
    tags {
      id
      title
      taxonomy
    }
    articleBannerForPreview
    fullWidthBanner {
      properties {
        imageAlt
        image {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fixed(width: 150, height: 150, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          focalPoint {
            x
            y
          }
        }
      }
    }
  }
`;

export default ArticlePreview;
